<template>
  <b-modal
    id="service_form_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
    :hide-footer="true"
  >
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <div
          class="headear-placeholder"
          v-show="mode === 'chooseServiceType'"
        ></div>
        <button
          class="close-modal-btn mr-7"
          id="service_form_modal_close_btn"
          @click="
            close();
            changeMode('chooseServiceType');
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <button
          v-show="mode !== 'chooseServiceType'"
          class="bb-btn btn-success header-save-btn"
          @click="submit()"
        >
          {{ $t("serviceFormModal.saveBtn") }}
        </button>
      </div>
      <div class="modal-header-title-container">
        <h2>{{ modalTitle }}</h2>
      </div>
    </template>
    <div
      class="choose-service-type-container"
      v-if="! isServiceTypeChoosen"
    >
      <div class="service-type-card" @click="changeMode('singleService')">
        <div class="d-flex justify-content-start align-items-center">
          <div class="bb-badge badge-rounded service-type-badge success-light">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-calendar"
            >
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="16" y1="2" x2="16" y2="6"></line>
              <line x1="8" y1="2" x2="8" y2="6"></line>
              <line x1="3" y1="10" x2="21" y2="10"></line>
            </svg>
          </div>
          <div class="service-card-text">
            <p>{{ $t("serviceFormModal.serviceTypes.single.name") }}</p>
            <span>{{
              $t("serviceFormModal.serviceTypes.single.description")
            }}</span>
          </div>
        </div>
        <i class="la la-angle-right"></i>
      </div>
      <div class="service-type-card" @click="changeMode('sessionService')">
        <div class="d-flex justify-content-start align-items-center">
          <div class="bb-badge badge-rounded service-type-badge success-light">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-users"
            >
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
          </div>
          <div class="service-card-text">
            <p>{{ $t("serviceFormModal.serviceTypes.session.name") }}</p>
            <span>{{
              $t("serviceFormModal.serviceTypes.session.description")
            }}</span>
          </div>
        </div>
        <!-- <div class="coming-soon">
          {{ $t("serviceFormModal.comingSoonLabel") }}
        </div> -->
        <i class="la la-angle-right"></i>
      </div>
    </div>
    <div class="single-service-container" v-if="isServiceTypeChoosen">
      <div class="bb-card basic-info-card">
        <div class="card-header">
          <h2 class="card-title">
            {{ $t("serviceFormModal.basicInfoCard.title") }}
          </h2>
          <span class="card-description">{{
            $t("serviceFormModal.basicInfoCard.description")
          }}</span>
        </div>
        <div class="card-body">
          <div class="form-inputs-container">
            <div class="bb-form-group">
              <label>{{
                $t("serviceFormModal.basicInfoCard.nameInput.label")
              }}</label>
              <input type="text" class="bb-input" v-model="form.fields.name" />
              <div class="bb-invalid-feedback" v-show="form.errors.name">
                {{ $t("serviceFormModal.validation.error") }} 
              </div>
            </div>
            <div class="bb-form-group" v-show="!currentUser.isInLawIndustry">
              <label>{{
                $t("serviceFormModal.basicInfoCard.typeInput.label")
              }}</label>
              <BBSelect
                :placeholder="
                  $t('serviceFormModal.basicInfoCard.typeInput.placeholder')
                "
                :options="serviceTypeOptions"
                v-model="form.fields.searchEngineTypeId"
              >
              </BBSelect>
              <span
                class="bb-input-description service-type-input-description"
                >{{
                  $t("serviceFormModal.basicInfoCard.typeInput.description")
                }}</span
              >
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.searchEngineTypeId"
              >
                {{ $t("serviceFormModal.validation.error") }} 
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("serviceFormModal.basicInfoCard.categoryInput.label")
              }}</label>
              <div
                class="bb-input-group category-input"
                v-click-outside="closeAvailableCategories"
              >
                <input
                  type="text"
                  :placeholder="
                    $t(
                      'serviceFormModal.basicInfoCard.categoryInput.placeholder'
                    )
                  "
                  v-model="form.fields.category"
                />
                <button
                  class="bb-btn btn-success"
                  @click="toggleAvailableCategories"
                >
                  <i :class="availableCategoriesArrowClass"></i>
                </button>
                <div
                  class="available-categories-list"
                  v-show="availableCategoriesVisible"
                >
                  <perfect-scrollbar class="scroll" style="height: 200px;">
                    <div
                      class="available-categories-item"
                      v-for="category in allServiceCategories"
                      :key="category.id"
                      @click="setCategory(category.name)"
                    >
                      {{ category.name }}
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
              <div class="bb-invalid-feedback" v-show="form.errors.category">
                {{ $t("serviceFormModal.validation.error") }} 
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("serviceFormModal.basicInfoCard.descriptionInput.label")
              }}</label>
              <b-form-textarea
                :placeholder="
                  $t(
                    'serviceFormModal.basicInfoCard.descriptionInput.placeholder'
                  )
                "
                rows="4"
                class="bb-textarea"
                v-model="form.fields.description"
              ></b-form-textarea>
              <div class="bb-invalid-feedback" v-show="form.errors.description">
                {{ $t("serviceFormModal.validation.error") }} 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bb-card online-booking-card">
        <div class="card-header">
          <h2 class="card-title">
            {{ $t("serviceFormModal.onlineBookingsCard.title") }}
          </h2>
          <span class="card-description">{{
            $t("serviceFormModal.onlineBookingsCard.description")
          }}</span>
        </div>
        <div class="card-body">
          <div class="bb-form-group">
            <BBSwitch
              v-model="form.fields.onlineBookings"
              :label="
                $t(
                  'serviceFormModal.onlineBookingsCard.onlineBookingsInput.label'
                )
              "
            />
            <div
              class="bb-invalid-feedback"
              v-show="form.errors.onlineBookings"
            >
              {{ $t("serviceFormModal.validation.error") }} 
            </div>
          </div>
        </div>
      </div>
      <div class="bb-card staff-card">
        <div class="card-header">
          <h2 class="card-title">
            {{ $t("serviceFormModal.staffCard.title") }}
          </h2>
        </div>
        <div class="card-body">
          <BBCheckboxGroup
            :options="staffOptions"
            v-model="form.fields.calendarIds"
            elementId="staff_checkboxes"
            :allCheckedLabel="
              $t('serviceFormModal.staffCard.staffInput.allLabel')
            "
          />
          <div class="bb-invalid-feedback" v-show="form.errors.calendarIds">
            {{ $t("serviceFormModal.validation.error") }} 
          </div>
        </div>
      </div>
      <div v-if="isInSessionServiceMode" class="bb-card">
        <div class="card-header">
          <h2 class="card-title">
            {{ $t("serviceFormModal.sameTimePeopleCard.title") }}
          </h2>
          <span class="card-description">{{
            $t("serviceFormModal.sameTimePeopleCard.description")
          }}</span>
        </div>
        <div class="card-body">
          <input class="bb-input same-time-people-input" type="number" v-model="form.fields.sameTimePeople" min="2" />
          <div class="bb-invalid-feedback" v-show="form.errors.sameTimePeople">
            {{ $t("serviceFormModal.validation.error") }} 
          </div>
        </div>
      </div>
      <div class="bb-card pricing-card">
        <div class="card-header">
          <h2 class="card-title">
            {{ $t("serviceFormModal.pricingCard.title") }}
          </h2>
          <span class="card-description">{{
            $t("serviceFormModal.pricingCard.description")
          }}</span>
        </div>
        <div class="card-body">
          <div class="price-and-duration-details">
            <p class="card-subtitle">
              {{ $t("serviceFormModal.pricingCard.priceTitle") }}
            </p>
            <div class="price-duration-inputs">
              <div class="bb-form-group">
                <label>{{
                  $t("serviceFormModal.pricingCard.durationInput.label")
                }}</label>
                <BBSelect
                  :options="durationOptions"
                  v-model="form.fields.duration"
                ></BBSelect>
                <div class="bb-invalid-feedback" v-show="form.errors.duration">
                  {{ $t("serviceFormModal.validation.error") }} 
                </div>
              </div>
              <div class="bb-form-group">
                <label>{{
                  $t("serviceFormModal.pricingCard.priceTypeInput.label")
                }}</label>
                <BBSelect
                  :options="priceTypeOptions"
                  v-model="form.fields.priceType"
                  class="price-type-input"
                ></BBSelect>
                <div class="bb-invalid-feedback" v-show="form.errors.priceType">
                  {{ $t("serviceFormModal.validation.error") }} 
                </div>
              </div>
              <div class="bb-form-group" v-show="priceInputVisible">
                <label>{{
                  $t("serviceFormModal.pricingCard.priceInput.label")
                }}</label>
                <div class="bb-input-group price-input">
                  <span>RSD</span>
                  <input type="number" v-model="form.fields.price" />
                </div>
                <div class="bb-invalid-feedback" v-show="form.errors.price">
                  {{ $t("serviceFormModal.validation.error") }} 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <p class="card-subtitle">
            {{ $t("serviceFormModal.pricingCard.extraTimeTitle") }}
          </p>
          <span class="card-subtitle-description">{{
            $t("serviceFormModal.pricingCard.extraTimeDescription")
          }}</span>
          <div class="bb-form-group">
            <BBSwitch
              v-model="form.fields.extraTimeEnabled"
              :label="
                $t('serviceFormModal.pricingCard.enableExtraTimeInput.label')
              "
            />
            <div
              class="bb-invalid-feedback"
              v-show="form.errors.extraTimeEnabled"
            >
              {{ $t("serviceFormModal.validation.error") }} 
            </div>
          </div>
          <div
            class="break-duration-container"
            v-show="form.fields.extraTimeEnabled"
          >
            <div class="break-duration-card">
              <p class="break-duration-title">
                {{ $t("serviceFormModal.pricingCard.extraTimeCard.title") }}
              </p>
              <span class="break-duration-description">{{
                $t("serviceFormModal.pricingCard.extraTimeCard.description")
              }}</span>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("serviceFormModal.pricingCard.breakDurationInput.label")
              }}</label>
              <BBSelect
                v-model="form.fields.breakDuration"
                :options="durationOptions"
              ></BBSelect>
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.breakDuration"
              >
                {{ $t("serviceFormModal.validation.error") }} 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-footer" v-show="isServiceTypeChoosen">
      <button class="bb-btn btn-success" @click="submit()">
        {{ $t("serviceFormModal.saveBtn") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import formMixin from "@/core/mixins/formMixin.js";
import serviceForm from "@/core/services/forms/serviceForm.js";
import { GET_SERVICE_CATEGORIES } from "@/core/services/store/serviceCategories.module.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import { mapGetters } from "vuex";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import BBSwitch from "@/view/content/BBForm/BBSwitch.vue";
import BBCheckboxGroup from "@/view/content/BBForm/BBCheckboxGroup.vue";
import searchEngineTypeMapper from "@/core/services/helpers/searchEngineTypeMapper.js";
import serviceDurationOptions from "@/core/services/helpers/serviceDurationOptions.js";

export default {
  name: "ServiceFormModal",
  mixins: [formMixin],
  components: { BBSelect, BBSwitch, BBCheckboxGroup },
  beforeMount() {
    // Get form object from serviceForm service
    this.form = JSON.parse(JSON.stringify(serviceForm));
  },
  mounted() {
    // Dispatch store GET_SERVICE_CATEGORIES action to get services from api
    this.$store.dispatch(GET_SERVICE_CATEGORIES);
    // Dispatch store GET_PLACES action to get all places from api
    this.$store.dispatch(GET_PLACES);
  },
  data() {
    return {
      mode: "chooseServiceType",
      availableModes: ["chooseServiceType", "singleService", "sessionService"],
      categorySearchTerm: "",
      priceTypeOptions: [
        {
          value: "FREE",
          text: this.$t("serviceFormModal.pricingCard.priceTypeDropdown.free")
        },
        {
          value: "FIXED",
          text: this.$t("serviceFormModal.pricingCard.priceTypeDropdown.fixed")
        },
        {
          value: "FROM",
          text: this.$t("serviceFormModal.pricingCard.priceTypeDropdown.from")
        }
      ],
      staffOptions: [],
      availableCategoriesVisible: false
    };
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
      this.mapStaffOptions();
    },
    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#service_form_modal_close_btn").click();
    },

    /**
     * Change component mode
     * @param {String} mode
     * @return {void}
     */
    changeMode(mode) {
      if (this.availableModes.includes(mode)) {
        this.mode = mode;
        this.form.fields.sameTimePeople = 1;
        if(this.isInSessionServiceMode) {
          // Set the minimum same time people for session service
          this.form.fields.sameTimePeople = 2;;
        }
      }   
    },

    /**
     * Toggle availableCategoriesVisible data property
     * @return {void}
     */
    toggleAvailableCategories() {
      this.availableCategoriesVisible = !this.availableCategoriesVisible;
    },

    /**
     * Set availableCategoriesVisible data property to false
     * @return {void}
     */
    closeAvailableCategories() {
      this.availableCategoriesVisible = false;
    },

    /**
     * Set category form field value
     * @param {String} value
     * @return {void}
     */
    setCategory(value) {
      this.form.fields.category = value;
    },

    /**
     * Map staff from all places into options for service form
     * @return {void}
     */
    mapStaffOptions() {
      this.staffOptions = [];
      this.allPlaces.forEach(place => {
        place.staff.forEach(staffMember => {
          if (staffMember.calendar) {
            let option = {
              text: staffMember.fullName + " (" + place.name + ")",
              value: staffMember.calendar.id
            };
            this.staffOptions.push(option);
          }
        });
      });
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.submitForm()
        .then(response => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null
          });
          this.closeModal();
          if (this.$route.name === "calendar") {
            this.$store.dispatch(GET_PLACES);
          } else {
            this.$store.dispatch(GET_SERVICE_CATEGORIES);
          }
        })
        .catch(error =>
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message]
          })
        );
    }
  },
  computed: {
    ...mapGetters([
      "allSearchEngineTypes",
      "allServiceCategories",
      "allPlaces",
      "currentUser"
    ]),

    /**
     * Return modal title based on mode
     * @return {String}
     */
    modalTitle() {
      if (this.mode === "singleService") {
        return this.$t("serviceFormModal.titles.singleService");
      }

      return this.$t("serviceFormModal.titles.chooseType");
    },

    /**
     * Return array of service type options
     * @return {Array}
     */
    serviceTypeOptions() {
      return searchEngineTypeMapper.mapmapSearchEngineTypesAsOptions(
        this.allSearchEngineTypes
      );
    },

    /**
     * Return options to display in select component
     * @return {Array}
     */
    displayServiceCategories() {
      return this.allServiceCategories.filter(category =>
        category.name
          .toLowerCase()
          .includes(this.categorySearchTerm.toLowerCase())
      );
    },

    /**
     * Return array of service duration options
     * @return {Array}
     */
    durationOptions() {
      return serviceDurationOptions.getOptions();
    },

    /**
     * Determine if price input is visible based on price type
     * @return {Boolean}
     */
    priceInputVisible() {
      return this.form.fields.priceType !== "FREE";
    },

    /**
     * Return class name for available categories list toggle button
     * @return {String}
     */
    availableCategoriesArrowClass() {
      if (this.availableCategoriesVisible) {
        return "la la-angle-up";
      }
      return "la la-angle-down";
    },

    /**
     * Determine if service type is choosen
     * @return {Boolean}
     */
    isServiceTypeChoosen() {
      return this.mode !== "chooseServiceType"
    },

    /**
     * Determine if service form is in session service mode
     * @return {Boolean}
     */
     isInSingleServiceMode() {
      return this.mode === "singleService"
    },

    /**
     * Determine if service form is in session service mode
     * @return {Boolean}
     */
    isInSessionServiceMode() {
      return this.mode === "sessionService"
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/service-form-modal.scss";
</style>
