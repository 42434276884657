<template>
  <div>
    <b-form-checkbox
      size="lg"
      v-model="allChecked"
      :aria-describedby="elementId"
      :aria-controls="elementId"
      class="bb-checkbox"
      @change="toggleAll"
    >
      {{ allLabel }}
    </b-form-checkbox>
    <b-form-checkbox-group
      :id="elementId"
      v-model="selected"
      :options="options"
      class="ml-0 bb-checkbox"
      name="services"
      stacked
      size="lg"
    ></b-form-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "BBCheckboxGroup",
  props: {
    /**
     * Label for all checkboxes selected
     * @type {String}
     */
    allCheckedLabel: {
      type: String,
      required: false
    },

    /**
     * Array of options for checkboxes group
     * @type {Array}
     */
    options: {
      type: Array,
      required: true
    },

    /**
     * Value for selected checkboxes
     * @type {Array}
     */
    value: {
      type: Array,
      required: true
    },

    /**
     * Id attribute for checkbox group element
     * @type {String}
     */
    elementId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      allChecked: false,
      selected: []
    };
  },
  methods: {
    /**
     * Toggle all checked checkboxes
     * @param {Boolean} checked
     * @emits input
     */
    toggleAll(checked) {
      this.selected = [];
      if (checked) {
        this.options.forEach(option => this.selected.push(option.value));
      }
      this.$emit("input", this.selected);
    }
  },
  computed: {
    /**
     * Return label for all checkboxes selected
     * @return {String}
     */
    allLabel() {
      return this.allCheckedLabel
        ? this.allCheckedLabel
        : this.$t("defaults.checkboxGroup.chooseAllLabel");
    }
  },
  watch: {
    /**
     * Watch for value data property
     */
    value: {
      immediate: true,
      handler() {
        this.selected = this.value;
      }
    },

    /**
     * Watch for selected data change to sync it with allChecked property
     * @param {Array} selectedOptions
     * @emits input
     */
    selected: {
      immediate: true,

      handler(selectedOptions) {
        if (selectedOptions.length === 0) {
          this.allChecked = false;
        } else if (selectedOptions.length === this.options.length) {
          this.allChecked = true;
        } else {
          this.allChecked = false;
        }
        this.$emit("input", this.selected);
      }
    },

    /**
     * Watch for options prop change to set allChecked property to false
     */
    options() {
      this.allChecked = false;
    }
  }
};
</script>

<style></style>
