const serviceForm = {
  /**
   * Service form key
   * @type {String}
   */
  key: "serviceForm",

  /**
   * Service form fields object
   * @type {Object}
   */
  fields: {
    name: "",
    category: "",
    description: "",
    price: "",
    isPricePublic: true,
    duration: 60,
    isDurationPublic: true,
    searchEngineTypeId: null,
    sameTimePeople: 1,
    breakDuration: 15,
    priceType: "FIXED",
    onlineBookings: true,
    extraTimeEnabled: false,
    calendarIds: []
  },

  /**
   * Service form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Service form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Service form path
   * @type {String}
   */
  path: "/api/b2b/services",

  /**
   * Service form helpers
   * @type {Object}
   */
   helpers: {
    isGroupService: false
   }
};

export default serviceForm;
