<template>
  <div
    id="mobile_services_filter"
    ref="mobile_services_filter"
    class="offcanvas offcanvas-right"
    v-click-outside="handleOutsideClick"
  >
    <button id="mobile_services_filter_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ $t("mobileServicesFilter.title") }}
      </h4>
      <a
        href="#"
        class=""
        id="mobile_services_filter_close"
        @click="closeOffcanvas('mobile_services_filter')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <div class="services-filters-container">
        <div class="service-filter-item" @click="updateFilter('services')">
          <div class="filter-description">
            <div class="filter-icon">
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.5V16.5H18.5V1.5H1.5ZM1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0V0Z"
                  fill="#33475B"
                />
                <path
                  d="M4 5H16H4ZM4 4H16C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8946 4.48043 17 4.73478 17 5C17 5.26522 16.8946 5.51957 16.7071 5.70711C16.5196 5.89464 16.2652 6 16 6H4C3.73478 6 3.48043 5.89464 3.29289 5.70711C3.10536 5.51957 3 5.26522 3 5C3 4.73478 3.10536 4.48043 3.29289 4.29289C3.48043 4.10536 3.73478 4 4 4ZM4 9H16H4ZM4 8H16C16.2652 8 16.5196 8.10536 16.7071 8.29289C16.8946 8.48043 17 8.73478 17 9C17 9.26522 16.8946 9.51957 16.7071 9.70711C16.5196 9.89464 16.2652 10 16 10H4C3.73478 10 3.48043 9.89464 3.29289 9.70711C3.10536 9.51957 3 9.26522 3 9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8ZM4 13H16H4ZM4 12H16C16.2652 12 16.5196 12.1054 16.7071 12.2929C16.8946 12.4804 17 12.7348 17 13C17 13.2652 16.8946 13.5196 16.7071 13.7071C16.5196 13.8946 16.2652 14 16 14H4C3.73478 14 3.48043 13.8946 3.29289 13.7071C3.10536 13.5196 3 13.2652 3 13C3 12.7348 3.10536 12.4804 3.29289 12.2929C3.48043 12.1054 3.73478 12 4 12Z"
                  fill="#33475B"
                />
              </svg>
            </div>
            <span class="filter-name">{{
              $t("mobileServicesFilter.services")
            }}</span>
          </div>
          <div class="filter-selected" v-show="currentFilter === 'services'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>
        <div class="service-filter-item" @click="updateFilter('categories')">
          <div class="filter-description">
            <div class="filter-icon">
              <svg
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.5V3.5H18.5V1.5H1.5ZM1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V4C20 4.26522 19.8946 4.51957 19.7071 4.70711C19.5196 4.89464 19.2652 5 19 5H1C0.734784 5 0.48043 4.89464 0.292893 4.70711C0.105357 4.51957 0 4.26522 0 4V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0V0ZM1.5 7.5V9.5H18.5V7.5H1.5ZM1 6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V10C20 10.2652 19.8946 10.5196 19.7071 10.7071C19.5196 10.8946 19.2652 11 19 11H1C0.734784 11 0.48043 10.8946 0.292893 10.7071C0.105357 10.5196 0 10.2652 0 10V7C0 6.73478 0.105357 6.48043 0.292893 6.29289C0.48043 6.10536 0.734784 6 1 6ZM1.5 13.5V15.5H18.5V13.5H1.5ZM1 12H19C19.2652 12 19.5196 12.1054 19.7071 12.2929C19.8946 12.4804 20 12.7348 20 13V16C20 16.2652 19.8946 16.5196 19.7071 16.7071C19.5196 16.8946 19.2652 17 19 17H1C0.734784 17 0.48043 16.8946 0.292893 16.7071C0.105357 16.5196 0 16.2652 0 16V13C0 12.7348 0.105357 12.4804 0.292893 12.2929C0.48043 12.1054 0.734784 12 1 12Z"
                  fill="#33475B"
                />
              </svg>
            </div>
            <span class="filter-name">{{
              $t("mobileServicesFilter.categories")
            }}</span>
          </div>
          <div class="filter-selected" v-show="currentFilter === 'categories'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import MobileServicesFilterLayout from "@/assets/js/layout/extended/mobile-services-filter.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { enableBodyScroll } from "body-scroll-lock";

export default {
  name: "MobileServicesFilter",
  props: {
    /**
     * Current filter
     * @type {String}
     */
    currentFilter: {
      type: String,
      required: true
    }
  },
  mounted() {
    // Init Date Input Panel
    MobileServicesFilterLayout.init(this.$refs["mobile_services_filter"]);
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        this.close();
      }
    },

    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
      let targetElement = document.querySelector("#mobile_services_filter");
      enableBodyScroll(targetElement);
    },

    /**
     * Close mobile services filter element
     * @return {void}
     */
    close() {
      document.querySelector("#mobile_services_filter_close").click();
    },

    /**
     * Emit filter updated event
     * @param {String} value
     * @emits filter-updated
     */
    updateFilter(value) {
      this.$emit("filter-updated", value);
      this.close();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/mobile-services-filter.scss";
</style>
