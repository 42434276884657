const serviceDurationOptions = {
  /**
   * Return array of service duration options
   * @return {Array}
   */
  getOptions: function() {
    let options = [];
    for (let h = 0; h <= 12; h++) {
      let mIncrement = 5;
      if (h >= 2 && h < 4) {
        mIncrement = 15;
      }
      if (h >= 4 && h < 8) {
        mIncrement = 30;
      }
      if (h >= 8) {
        mIncrement = 60;
      }
      for (let m = 0; m <= 55; m += mIncrement) {
        let option = {};
        if (h > 0) {
          if (m === 0) {
            option.value = h * 60 + m;
            option.text = h + "h";
          } else {
            option.value = h * 60 + m;
            option.text = h + "h" + " " + m + "min";
          }
        } else {
          if (m === 0) {
            continue;
          }
          option.value = m;
          option.text = m + "min";
        }
        options.push(option);
      }
    }

    return options;
  }
};

export default serviceDurationOptions;
