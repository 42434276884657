<template>
  <div id="services_page_wrapper">
    <div class="bb-mobile-header">
      <div class="bb-mobile-header-left">
        <button class="mobile-sidebar-btn" @click="openMobileAside()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
      </div>
      <h1>{{ $t("services.title") }}</h1>
      <div class="bb-mobile-header-right">
        <button @click="openMobileServicesFilter()">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0642 0H3.93577C1.76559 0 0 1.76559 0 3.93577V18.0643C0 20.2344 1.76559 22 3.93577 22H18.0643C20.2344 22 22 20.2344 22 18.0642V3.93577C22 1.76559 20.2344 0 18.0642 0ZM20.7109 18.0642C20.7109 19.5236 19.5236 20.7109 18.0642 20.7109H3.93577C2.47638 20.7109 1.28906 19.5236 1.28906 18.0642V3.93577C1.28906 2.47638 2.47638 1.28906 3.93577 1.28906H18.0643C19.5236 1.28906 20.7109 2.47638 20.7109 3.93577V18.0642Z"
              fill="#253342"
            />
            <path
              d="M18.5942 4.83262H8.77756C8.50767 4.03104 7.74949 3.4519 6.85806 3.4519C5.96663 3.4519 5.20845 4.03104 4.93856 4.83262H3.40625C3.0503 4.83262 2.76172 5.1212 2.76172 5.47715C2.76172 5.8331 3.0503 6.12168 3.40625 6.12168H4.9386C5.20849 6.92326 5.96667 7.5024 6.8581 7.5024C7.74953 7.5024 8.50772 6.92326 8.7776 6.12168H18.5943C18.9502 6.12168 19.2388 5.8331 19.2388 5.47715C19.2388 5.1212 18.9502 4.83262 18.5942 4.83262ZM6.85806 6.21333C6.45213 6.21333 6.12188 5.88308 6.12188 5.47715C6.12188 5.07122 6.45213 4.74097 6.85806 4.74097C7.26399 4.74097 7.59424 5.07122 7.59424 5.47715C7.59424 5.88308 7.26399 6.21333 6.85806 6.21333Z"
              fill="#253342"
            />
            <path
              d="M18.5942 10.3556H17.0619C16.792 9.55399 16.0338 8.97485 15.1424 8.97485C14.251 8.97485 13.4928 9.55399 13.2229 10.3556H3.40625C3.0503 10.3556 2.76172 10.6441 2.76172 11.0001C2.76172 11.3561 3.0503 11.6446 3.40625 11.6446H13.2229C13.4928 12.4462 14.251 13.0253 15.1424 13.0253C16.0338 13.0253 16.792 12.4462 17.0619 11.6446H18.5943C18.9502 11.6446 19.2388 11.3561 19.2388 11.0001C19.2388 10.6441 18.9502 10.3556 18.5942 10.3556ZM15.1424 11.7363C14.7365 11.7363 14.4063 11.406 14.4063 11.0001C14.4063 10.5942 14.7365 10.2639 15.1424 10.2639C15.5484 10.2639 15.8786 10.5942 15.8786 11.0001C15.8786 11.406 15.5484 11.7363 15.1424 11.7363Z"
              fill="#253342"
            />
            <path
              d="M18.5942 15.8784H11.539C11.2691 15.0768 10.511 14.4977 9.61953 14.4977C8.7281 14.4977 7.96992 15.0768 7.70003 15.8784H3.40625C3.0503 15.8784 2.76172 16.167 2.76172 16.5229C2.76172 16.8789 3.0503 17.1675 3.40625 17.1675H7.70003C7.96992 17.969 8.7281 18.5482 9.61953 18.5482C10.511 18.5482 11.2691 17.969 11.539 17.1675H18.5943C18.9502 17.1675 19.2388 16.8789 19.2388 16.5229C19.2388 16.167 18.9502 15.8784 18.5942 15.8784ZM9.61953 17.2592C9.21361 17.2592 8.88335 16.9289 8.88335 16.523C8.88335 16.117 9.21361 15.7868 9.61953 15.7868C10.0255 15.7868 10.3557 16.117 10.3557 16.5229C10.3557 16.9289 10.0255 17.2592 9.61953 17.2592Z"
              fill="#253342"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-between services-filter-container">
      <div class="bb-view-filter">
        <b-form-group>
          <b-form-radio-group
            ref="switchView"
            id="bb_table_view_filter_toggle"
            v-model="selectedView"
            :options="tableViews"
            buttons
            name="radios-btn-default"
            @change="switchView()"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <button
        class="bb-btn btn-success btn-lg new-staff-btn"
        @click="createService()"
      >
        {{ $t("services.createServiceBtn") }}
      </button>
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="servicesItems"
      hide-default-footer
      hide-default-header
      group-by="serviceCategoryName"
      show-group-by
      ref="servicesTable"
      class="bb-table services-table"
      :disable-pagination="true"
    >
      <template v-slot:group.header="{ headers, group }">
        <td class="bb-table-group-header" :colspan="headers.length">
          <div class="d-flex justify-content-between align-items-center">
            <div
              class="d-flex align-items-center"
              v-if="!isInputVisible(group)"
            >
              <span
                v-show="!isInputVisible(group)"
                class="bb-table-group-header-text"
                >{{ group }}</span
              >
              <button v-show="!isInputVisible(group)" @click="showInput(group)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#516F90"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit-2"
                >
                  <path
                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                  ></path>
                </svg>
              </button>
            </div>
            <input
              v-show="isInputVisible(group)"
              v-model="input.value"
              class="bb-input edit-category-name-input"
              type="text"
              @blur="updateServiceCategoryName()"
              :id="group.toLowerCase().replace(/ /g, '_') + '_input'"
            />
            <div class="d-flex align-items-center">
              <button
                class="table-header-add-service-btn"
                @click="createService({ category: group })"
              >
                {{ $t("services.table.createServiceBtn") }}
              </button>
              <BBDropdown
                positionClass="right"
                class="service-category-dropdown"
              >
                <template v-slot:toggle>
                  <i class="ki ki-more-ver"></i>
                </template>
                <template v-slot:items="{ closeDropdown }">
                  <div
                    class="action-item"
                    @click="createService({ category: group })"
                  >
                    <span>{{
                      $t("services.table.dropdown.createServiceBtn")
                    }}</span>
                  </div>
                  <div
                    class="action-item"
                    @click="
                      showInput(group);
                      closeDropdown();
                    "
                  >
                    <span>{{
                      $t("services.table.dropdown.editCategoryNameBtn")
                    }}</span>
                  </div>
                  <div
                    class="action-item danger"
                    @click="
                      deleteServiceCategory(group);
                      closeDropdown();
                    "
                  >
                    <span>{{
                      $t("services.table.dropdown.deleteCategoryBtn")
                    }}</span>
                  </div>
                </template>
              </BBDropdown>
            </div>
            <button
              class="text-right mobile-edit-service-category-btn"
              style='position: absolute; right: 25px;'
              @click="showMobileActions(group)"
            >
              <i class="ki ki-more-ver"></i>
            </button>
          </div>
        </td>
      </template>
      <template v-slot:item="{ item }">
        <tr class="service-item-row" @click="handleTableRowClick(item)">
          <td class="text-left name-cell">
            {{ item.name }}
            <span class="mobile-duration-text">{{ item.duration }}</span>
            <span class="mobile-price-text">{{ item.price }}</span>
          </td>
          <td class="text-left description-cell">
            {{ 
              item.description ? item.description.substring(0, 85)+".." : $t("services.table.noDescriptionLabel")
            }}
          </td>
          <td class="text-left duration-cell">{{ item.duration }}</td>
          <td class="text-left group-label-cell">
            <span v-show="item.isGroupService">
              {{ `${$t("services.table.groupServiceLabel")} (${item.sameTimePeople})` }}
            </span>
          </td>
          <td class="text-right edit-cell">
            <div class="table-action-buttons">
              <button
                class="table-action-button"
                @click="editService(getService(item))"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit"
                >
                  <path
                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                  ></path>
                  <path
                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                  ></path>
                </svg>
              </button>
              <button
                class="table-action-button"
                @click="deleteService(getService(item))"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                  ></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <button class="mobile-new-service-btn" @click="createService()">
      <i class="la la-plus"></i>
    </button>
    <div
      id="mobile_actions_container"
      class="mobile-service-actions-container"
      v-show="mobileActionsVisible"
      v-click-outside="handleMobileActionsOutsideClick"
    >
      <div class="mobile-service-actions">
        <button
          class="mobile-service-action-btn"
          @click="
            createService({ category: selectedCategoryName });
            hideMobileActions();
          "
        >
          {{ $t("services.mobileButtons.createServiceBtn") }}
        </button>
        <button
          class="mobile-service-action-btn"
          @click="
            showInput(selectedCategoryName);
            hideMobileActions();
          "
        >
          {{ $t("services.mobileButtons.editCategoryNameBtn") }}
        </button>
        <button
          class="mobile-service-action-btn danger"
          @click="
            deleteServiceCategory(selectedCategoryName);
            hideMobileActions();
          "
        >
          {{ $t("services.mobileButtons.deleteCategoryBtn") }}
        </button>
      </div>
      <button class="mobile-service-action-btn" @click="hideMobileActions">
        {{ $t("services.mobileButtons.closeBtn") }}
      </button>
    </div>
    <div
      id="mobile_service_actions_container"
      class="mobile-service-actions-container"
      v-show="mobileServiceActionsVisible"
      v-click-outside="handleMobileServiceActionsOutsideClick"
    >
      <div class="mobile-service-actions">
        <button
          class="mobile-service-action-btn"
          @click="
            editService(selectedService);
            hideMobileServiceActions();
          "
        >
          {{ $t("services.mobileServiceButtons.editServiceBtn") }}
        </button>
        <button
          class="mobile-service-action-btn danger"
          @click="
            deleteService(selectedService);
            hideMobileServiceActions();
          "
        >
          {{ $t("services.mobileServiceButtons.deleteServiceBtn") }}
        </button>
      </div>
      <button
        class="mobile-service-action-btn"
        @click="hideMobileServiceActions"
      >
        {{ $t("services.mobileServiceButtons.closeBtn") }}
      </button>
    </div>
    <MobileServicesFilter
      :currentFilter="selectedView"
      @filter-updated="filterUpdated($event)"
    />
    <ServiceFormModal ref="serviceFormModal" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import serviceFormMixin from "@/core/mixins/serviceFormMixin.js";
import serviceFormModalMixin from "@/core/mixins/serviceFormModalMixin.js";
import { GET_SERVICE_CATEGORIES } from "@/core/services/store/serviceCategories.module.js";
import { mapGetters } from "vuex";
import BBDropdown from "@/view/content/BBDropdown.vue";
import { GET_SEARCH_ENGINE_TYPES } from "@/core/services/store/searchEngineTypes.module";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import formMixin from "@/core/mixins/formMixin.js";
import httpService from "@/core/services/http.service.js";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";
import MobileServicesFilter from "@/view/pages/components/Services/MobileServicesFilter.vue";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import ServiceFormModal from "@/view/layout/extras/modal/ServiceFormModal.vue";

export default {
  name: "Services",
  components: { BBDropdown, MobileServicesFilter, ServiceFormModal },
  mixins: [serviceFormModalMixin, formMixin, mobileAsideMixin],
  data() {
    return {
      form: {
        key: "serviceCategoryForm",
        mode: "edit",
        path: "/api/b2b/service-categories",
        fields: {
          _method: "PUT",
          name: ""
        }
      },
      input: {
        visible: false,
        categoryName: "",
        value: ""
      },
      tableHeaders: [
        {
          text: "Name",
          value: "name",
          align: "left",
          sortable: false,
          cellClass: "service-cell"
        },
        {
          text: "Description",
          value: "description",
          align: "left",
          sortable: false,
          cellClass: "service-cell"
        },
        {
          text: "Duration",
          value: "duration",
          align: "left",
          sortable: false,
          cellClass: "service-cell"
        },
        {
          text: "Price",
          value: "price",
          align: "left",
          sortable: false,
          cellClass: "service-cell"
        },
        {
          text: "",
          value: "id",
          align: "right",
          sortable: false,
          cellClass: "service-cell"
        }
      ],
      selectedView: "services",
      tableViews: [
        { text: this.$t("services.tableFilter.services"), value: "services" },
        {
          text: this.$t("services.tableFilter.categories"),
          value: "categories"
        }
      ],
      mobileActionsVisible: false,
      selectedCategoryName: null,
      mobileServiceActionsVisible: false,
      selectedService: null
    };
  },
  mounted() {
    // Dispatch store SET_BREADCRUMB action to set page title
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("services.title") }
    ]);
    // Dispatch store GET_SERVICE_CATEGORIES action to get services from api
    this.$store.dispatch(GET_SERVICE_CATEGORIES);
    // Dispatch store GET_SEARCH_ENGINE_TYPES action to get search engine types from api
    this.$store.dispatch(GET_SEARCH_ENGINE_TYPES);
  },
  methods: {
    /**
     * Handle mobile actions outside click
     * @param {Object} event
     * @return {void}
     */
    handleMobileActionsOutsideClick(event) {
      if (
        event.target.classList.contains("offcanvas-overlay") &&
        this.mobileActionsVisible
      ) {
        this.hideMobileActions();
      }
    },

    /**
     * Handle mobile service actions outside click
     * @param {Object} event
     * @return {void}
     */
    handleMobileServiceActionsOutsideClick(event) {
      if (
        event.target.classList.contains("offcanvas-overlay") &&
        this.mobileServiceActionsVisible
      ) {
        this.hideMobileServiceActions();
      }
    },

    /**
     * Get the original service object from table item
     * @param {Object} serviceItem
     * @return {Object}
     */
    getService(serviceItem) {
      let serviceCategory = this.allServiceCategories.find(
        category => category.name === serviceItem.serviceCategoryName
      );
      let service = serviceCategory.services.find(
        service => service.id === serviceItem.id
      );
      service.category = serviceCategory.name;
      return service;
    },

    /**
     * Determine if text input for category name is visible
     * @param {String} categoryName
     * @return {Boolean}
     */
    isInputVisible(categoryName) {
      if (this.input.visible) {
        if (this.input.categoryName === categoryName) {
          return true;
        }
        return false;
      }
      return false;
    },

    /**
     * Show text input for category name
     * @param {String} categoryName
     * @return {void}
     */
    showInput(categoryName) {
      this.input.visible = true;
      this.input.categoryName = categoryName;
      this.input.value = categoryName;
      let elementId = categoryName.toLowerCase().replace(/ /g, "_") + "_input";
      this.$nextTick(function() {
        document.querySelector("#" + elementId).focus();
      });
    },

    /**
     * Update service category name
     * @return {void}
     */
    updateServiceCategoryName() {
      let category = this.allServiceCategories.find(
        serviceCategory => serviceCategory.name === this.input.categoryName
      );
      if (category) {
        if (category.name !== this.input.value) {
          this.form.fields.name = this.input.value;
          this.form.path = this.form.path + "/" + category.id;
          this.initForm();
          this.submitForm()
            .then(response => {
              this.$store.dispatch(GET_SERVICE_CATEGORIES);
              this.$store.dispatch(SET_ALERT_MESSAGES, {
                successMessages: [response.data.message],
                errorMessages: null
              });
            })
            .catch(error => {
              this.$store.dispatch(SET_ALERT_MESSAGES, {
                successMessages: null,
                errorMessages: [error.response.data.message]
              });
            });
        }
        this.form.path = "/api/b2b/service-categories";
        // this.removeForm();
        this.hideInput();
      }
    },

    /**
     * Delete service category
     * @param {String} serviceCategoryName
     * @return {void}
     */
    deleteServiceCategory(serviceCategoryName) {
      let category = this.allServiceCategories.find(
        serviceCategory => serviceCategory.name === serviceCategoryName
      );
      if (category) {
        httpService
          .delete(this.form.path + "/" + category.id)
          .then(response => {
            this.$store.dispatch(SET_ALERT_MESSAGES, {
              successMessages: [response.data.message],
              errorMessages: null
            });
            this.$store.dispatch(GET_SERVICE_CATEGORIES);
          })
          .catch(error => {
            this.$store.dispatch(SET_ALERT_MESSAGES, {
              successMessages: null,
              errorMessages: [error.response.data.message]
            });
          });
      }
    },

    /**
     * Delete service
     * @param {Object} service
     * @return {void}
     */
    deleteService(service) {
      httpService
        .delete("/api/b2b/services/" + service.id)
        .then(response => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null
          });
          this.$store.dispatch(GET_SERVICE_CATEGORIES);
        })
        .catch(error => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message]
          });
        });
    },

    /**
     * Hide all text inputs
     * @return {void}
     */
    hideInput() {
      this.input.visible = false;
      this.input.categoryName = "";
      this.input.value = "";
    },

    /**
     * Switch table view
     * @return {void}
     */
    switchView() {
      setTimeout(() => {
        switch (this.selectedView) {
          case "services":
            for (const name of Object.keys(
              this.$refs.servicesTable.openCache
            )) {
              this.$refs.servicesTable.openCache[name] = true;
            }
            break;
          case "categories":
            for (const name of Object.keys(
              this.$refs.servicesTable.openCache
            )) {
              this.$refs.servicesTable.openCache[name] = false;
            }
            break;
          default:
            return;
        }
      }, 100);
    },

    /**
     * Handle table row click
     * @param {Object} item
     * @return {void}
     */
    handleTableRowClick(item) {
      if (window.innerWidth < 992) {
        //this.editService(this.getService(item));
        this.showMobileServiceActions(this.getService(item));
      }
    },

    /**
     * Show mobile actions
     * @param {String} categoryName
     * @return {void}
     */
    showMobileActions(categoryName) {
      this.selectedCategoryName = categoryName;
      this.mobileActionsVisible = true;
      this.$emit("toggle-overlay");
      let targetElement = document.querySelector("#mobile_actions_container");
      disableBodyScroll(targetElement);
    },

    /**
     * Show mobile service actions
     * @param {Object} service
     * @return {void}
     */
    showMobileServiceActions(service) {
      this.selectedService = service;
      this.mobileServiceActionsVisible = true;
      this.$emit("toggle-overlay");
      let targetElement = document.querySelector(
        "#mobile_service_actions_container"
      );
      disableBodyScroll(targetElement);
    },

    /**
     * Hide mobile actions
     * @return {void}
     */
    hideMobileActions() {
      this.selectedCategoryName = null;
      this.mobileActionsVisible = false;
      this.$emit("toggle-overlay");
      let targetElement = document.querySelector("#mobile_actions_container");
      enableBodyScroll(targetElement);
    },

    /**
     * Hide mobile service actions
     * @return {void}
     */
    hideMobileServiceActions() {
      this.selectedService = null;
      this.mobileServiceActionsVisible = false;
      this.$emit("toggle-overlay");
      let targetElement = document.querySelector(
        "#mobile_service_actions_container"
      );
      enableBodyScroll(targetElement);
    },

    /**
     * Open mobile services filter by imitating click on mobile_services_filter_toggle element
     * @return {void}
     */
    openMobileServicesFilter() {
      document.querySelector("#mobile_services_filter_toggle").click();
      let targetElement = document.querySelector("#mobile_services_filter");
      disableBodyScroll(targetElement);
    },

    /**
     * Handle filter-updated event from mobileServiceFilter
     * @param {String} filterValue
     * @return {void}
     */
    filterUpdated(filterValue) {
      this.selectedView = filterValue;
      this.switchView();
    }
  },
  computed: {
    ...mapGetters(["allServiceCategories"]),

    /**
     * Return array of services for datatable
     * @return {Array}
     */
    servicesItems() {
      let servicesItems = [];
      if (this.allServiceCategories) {
        this.allServiceCategories.forEach(serviceCategory => {
          serviceCategory.services.forEach(service => {
            let serviceItem = JSON.parse(JSON.stringify(service));
            serviceItem.serviceCategoryName = serviceCategory.name;
            serviceItem.duration =
              serviceItem.duration +
              " " +
              this.$t("services.table.minutesLabel");
            serviceItem.price = serviceItem.price + " RSD";
            servicesItems.push(serviceItem);
          });
        });
      }
      return servicesItems;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/pages/services.scss";
</style>
