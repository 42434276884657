import serviceForm from "@/core/services/forms/serviceForm.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";

export default {
  methods: {
    /**
     * Init service form with default values from serviceForm object and open service form modal element
     * @param {Object|null} fields
     * @return {void}
     */
    createService(fields = null) {
      let form = JSON.parse(JSON.stringify(serviceForm));
      if (fields) {
        for (const [key, value] of Object.entries(fields)) {
          form.fields[key] = value;
        }
      }
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.serviceFormModal) {
        this.$refs.serviceFormModal.show();
      }
    },

    /**
     * Init service form with edit mode and open service form element
     * @param {Object} service
     * @return {void}
     */
    editService(service) {
      let form = JSON.parse(JSON.stringify(serviceForm));
      form.mode = "edit";
      form.path = form.path + "/" + service.id;
      let formFields = {
        _method: "PUT",
        name: service.name,
        description: service.description,
        price: service.price,
        isPricePublic: service.isPricePublic,
        duration: service.duration,
        isDurationPublic: service.isDurationPublic,
        sameTimePeople: service.sameTimePeople,
        breakDuration: service.breakDuration,
        searchEngineTypeId: service.searchEngine
          ? service.searchEngine.id
          : null,
        category: service.category ?? "",
        priceType: service.priceType,
        onlineBookings: service.onlineBookings,
        extraTimeEnabled: service.extraTimeEnabled,
        calendarIds: service.calendarIds ? service.calendarIds : []
      };
      form.fields = formFields;
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.serviceFormModal) {
        this.$refs.serviceFormModal.show();
        let mode = service.isGroupService ? "sessionService" : "singleService";
        this.$refs.serviceFormModal.changeMode(mode);
      }
    }
  }
};
